import { Button, Drawer } from "@global";
import { Container, P } from "@util/standard";
import React, { useContext, useEffect, useState } from "react";

import Account from "./drawer/account";
import { assets } from "@util/constants";
import { logoutUser } from "@auth/getFirebase";
import styled from "styled-components";
import { AuthContext } from "@auth/authContext";
import { useStore } from "@state/store";
import { navigate } from "gatsby";
import { useCheckUserIsGroupAdmin } from "@util/useFirestoreDocFuncs";
import { isBrowser } from "@util/helper";

interface Props {
  state: number;
  setState: (state: number) => void;
  showLogo?: boolean;
  setRunTour?: (runTour: boolean) => void;
  overrideSetMenuOpen?: (menuOpen: boolean) => void;
}

const StyledHeader = styled.header<{ showLogo?: boolean }>`
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: ${props => (props.showLogo ? "space-between" : "flex-end")};

  .username {
    margin: auto 0 auto 10px;
    font-weight: 700;
  }

  .logo {
    width: 150px;
  }

  a {
    margin: auto;
    margin-right: 15px;
    font-size: 15px;
  }

  .drop-down {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    position: absolute;
    z-index: 100;
    right: 100px;
    top: 90px;
    border-radius: 20px;
    background-color: white;
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      margin: 5px auto 5px 0px;
      cursor: pointer;
      width: 90px;
      &:hover {
        font-weight: 700;
      }
    }
  }
`;

const StyledMenuButton = styled.div<{ enabled?: boolean }>`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .arrow {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
    width: 20px;
    margin: auto;
    ${props => props.enabled && `transform: rotate(0deg);`}
  }
`;

const Header = ({ state, setState, showLogo = false, setRunTour, overrideSetMenuOpen }: Props) => {
  // add your child elements inside of <nav></nav>
  const [menuOpen, setMenuOpen] = useState(false);
  const { authUser } = useContext(AuthContext);
  const { user, setIsGroupAdmin, isGroupAdmin } = useStore();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setDropdownOpen(false);
  }, [state]);

  useEffect(() => {
    if (authUser) setMenuOpen(false);
  }, [authUser]);

  return (
    <>
      <StyledHeader className="header" showLogo={showLogo}>
        {showLogo && (
          <img onClick={() => navigate("/dashboard")} className="logo" src={assets.dashboardLogo} />
        )}
        <Container height="50px" id="profile">
          {/* <a>Live Q&A</a>
          <a>Help center</a> */}
          {/* <StyledIMG /> */}
          {user && <p className="username">Settings</p>}
          <StyledMenuButton
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            enabled={dropdownOpen}
          >
            <img className="arrow" src={assets.accordionActive} />
          </StyledMenuButton>
          {dropdownOpen && (
            <div className="drop-down">
              {user?.isGroupAdmin && (
                <a
                  onClick={() => {
                    navigate("/houston");
                  }}
                >
                  Houston
                </a>
              )}
              <a
                onClick={() => {
                  navigate("/dashboard");
                  setState(0);
                }}
              >
                Dashboard
              </a>
              {isBrowser() && !window.location.pathname.includes("houston") && (
                <a
                  onClick={() => {
                    setState(1);
                  }}
                >
                  My Account
                </a>
              )}

              <a onClick={logoutUser}>Logout</a>
              <a
                onClick={() => {
                  // navigate("/houston");
                  setRunTour && setRunTour(true);
                }}
              >
                Restart tour
              </a>
            </div>
          )}
        </Container>
      </StyledHeader>
      <Drawer visible={menuOpen} onClose={() => setMenuOpen(false)}>
        <Account onLoggedIn={() => setMenuOpen(false)} />
      </Drawer>
    </>
  );
};

export default Header;
