import { CompletedPages, EngagementProps, EvaluationObject, GlobalQuiz, User } from "./types";
import { SanityGlobalQuizQuestion, SanitySocialPoll, SanityWorkshop } from "@graphql-types";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  useFirebaseGetSocialPoll,
  useFirebaseUpdateEvaluation,
  useFirebaseUpdateUser,
} from "@util/useFirestoreDocFuncs";

import { database } from "@auth/getFirebase";
import { useStore } from "./store";
import { PageContext } from "@util/types";
import { isBrowser } from "@util/helper";

export const useAddFortuneToQuiz = (
  quizRef: string,
  fortuneCard: SanityGlobalQuizQuestion,
  globalQuizArray: GlobalQuiz[] | undefined,
) => {
  if (globalQuizArray == undefined) return;

  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  const filterCurrentFortune =
    globalQuizRef?.fortunes && globalQuizRef?.fortunes.filter(item => item.id !== fortuneCard._id);

  const fortuneObject = {
    id: fortuneCard._id,
    score: fortuneCard.score,
  };

  const fortuneArray = filterCurrentFortune
    ? [...filterCurrentFortune, fortuneObject]
    : [fortuneObject];

  const totalScore = useReturnCumulativeScore(
    globalQuizRef?.questions,
    fortuneArray,
    globalQuizRef?.initialScoreValue,
  );

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, fortunes: fortuneArray, score: totalScore } : x,
  );

  return updatedData;
};

export const useAddGlobalQuizAnswer = (
  quizRef: string,
  question: any,
  globalQuizArray: GlobalQuiz[] | undefined,
) => {
  if (globalQuizArray == undefined) return;

  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  const filterCurrentQuestion =
    globalQuizRef?.questions &&
    globalQuizRef?.questions.filter(item => item._id !== question.question._id);

  const newQuestion = {
    _id: question.question._id,
    selectedAnswer: question.selectedAnswer,
  };

  const updatedQuestionArray = filterCurrentQuestion
    ? [...filterCurrentQuestion, newQuestion]
    : [newQuestion];

  const totalScore = useReturnCumulativeScore(
    updatedQuestionArray,
    globalQuizRef?.fortunes,
    globalQuizRef?.initialScoreValue,
  );

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, questions: updatedQuestionArray, score: totalScore } : x,
  );

  return updatedData;
};

export const useReturnCumulativeScore = (
  questions?: any,
  fortunes?: any,
  initialScore?: number,
) => {
  if (questions == null && fortunes == null) return;

  let totalScore = initialScore ?? 0;

  questions?.map(question => {
    if (question.selectedAnswer && question.selectedAnswer.score) {
      totalScore = totalScore + question.selectedAnswer.score;
    }
  });

  fortunes?.map(fortune => {
    if (fortune && fortune.score) {
      totalScore = totalScore + fortune.score;
    }
  });

  return totalScore;
};

export const useClearQuiz = (quizRef: string, globalQuizArray: GlobalQuiz[] | undefined) => {
  if (globalQuizArray == undefined) return;
  const globalQuizRef = globalQuizArray.find(quiz => quiz.id === quizRef);

  if (globalQuizRef == undefined || globalQuizRef.score == null) return;

  const updatedData = globalQuizArray.map(x =>
    x.id === globalQuizRef?.id ? { ...x, questions: [], score: 0, fortunes: [] } : x,
  );

  return updatedData;
};

export const useAddTextInput = (
  textInputsArray: any[],
  textInput: string,
  id: string,
  userID: string | undefined,
) => {
  const newTextInput = {
    text: textInput,
    id: id,
  };

  const find = textInputsArray && textInputsArray.find(x => x.id == id);
  if (find) {
    const updatedData = textInputsArray.map(x => (x.id === id ? { ...newTextInput } : x));

    if (userID) {
      const docRef = database ? doc(database, "users", userID) : undefined;
      if (docRef) {
        updateDoc(docRef, { textInputs: updatedData });
      }
    }

    return updatedData;
  }

  const newArray =
    textInputsArray && textInputsArray.length > 0
      ? [...textInputsArray, newTextInput]
      : [newTextInput];

  if (userID) {
    const docRef = database ? doc(database, "users", userID) : undefined;
    if (docRef) {
      updateDoc(docRef, { textInputs: newArray });
    }
  }

  return newArray;
};

export const useGetTextInput = (textInputsArray: any[], id: string) => {
  const find = textInputsArray.find(x => x.id == id);
  return find;
};

export const useReturnOrSetupSocialPoll = async (socialPoll: SanitySocialPoll) => {
  if (socialPoll == null || socialPoll._id == null) return;

  const currentSocialPoll = undefined;
  // await useGetDoc("social_polls", socialPoll._id);
  if (currentSocialPoll == undefined) {
    const answers = socialPoll.answers?.map(answer => {
      return {
        answerID: answer?._key,
        answerCount: 0,
        answer: answer?.answer,
      };
    });

    const newSocialPoll = {
      totalAnswers: 0,
      id: socialPoll._id,
      answers: answers,
    };

    // const set = await useSetDoc("social_polls", newSocialPoll, socialPoll._id);
    // return set;
  }

  return currentSocialPoll;
};

export const useUpdateSocialPoll = async (socialPoll: any, answer: string) => {
  if (socialPoll == null || answer == null) return;

  const updatedAnswerArray = socialPoll.answers.map(item => {
    if (item.answerID == answer) {
      return {
        answerID: item.answerID,
        answerCount: item.answerCount + 1,
        answer: item.answer,
      };
    }
    return item;
  });

  const updatedSocialPoll = {
    id: socialPoll.id,
    totalAnswers: socialPoll.totalAnswers + 1,
    answers: updatedAnswerArray,
  };

  const set = useSetDoc("social_polls", updatedSocialPoll, socialPoll.id);
  return set;
};

export const useReturnSocialPollPercentage = async (questionID: string, answerID: string) => {
  const res = await useFirebaseGetSocialPoll("social_polls", questionID);

  console.log({ res });

  if (res == null) return;

  const answer = res.answers.find(item => item.answerID == answerID);
  const per = answer.answerCount / res.totalAnswers;
  const calc = per * 100;
  return calc.toFixed();
};

export const useAddGoal = (
  goals: any[],
  goalGroup: string,
  fieldType: string,
  text: string,
  userID: string | undefined,
) => {
  // Ensure goals is an array or fallback to an empty array
  const findGroup = goals?.find(item => item.groupID == goalGroup);
  const filter = goals?.filter(item => item.groupID !== goalGroup) || [];
  let updatedArray;

  if (findGroup) {
    // Ensure findFields is an array or fallback to an empty array
    const findFields = findGroup?.fields?.filter(item => item.fieldType !== fieldType) || [];

    const update = [
      ...findFields,
      {
        fieldType: fieldType,
        text: text,
      },
    ];

    updatedArray = [
      ...filter,
      {
        groupID: goalGroup,
        fields: [...update],
      },
    ];
  } else {
    updatedArray = [
      ...filter,
      {
        groupID: goalGroup,
        fields: [
          {
            fieldType: fieldType,
            text: text,
          },
        ],
      },
    ];
  }

  // Ensure that the database and userID are valid before attempting to update the document
  if (userID && database) {
    const docRef = doc(database, "users", userID);
    if (docRef) {
      updateDoc(docRef, { goals: updatedArray })
        .then(() => {
          console.log("Goals updated successfully.");
        })
        .catch(error => {
          console.error("Error updating goals:", error);
        });
    }
  }

  return updatedArray;
};

export const useUpdateCompletedPage = (
  completedPage: any,
  completed: CompletedPages[] | undefined,
  userID: string | undefined,
  currentPageContext?: PageContext,
  engagement?: any,
) => {
  if (!completedPage || !userID) return;

  const update = (completed ?? []).map(workshop => {
    if (workshop.id !== completedPage.workshop_id) return workshop;

    const section = workshop.sections.find(sec => sec.id === completedPage.section_id);
    const newPage = {
      id: completedPage.page_id,
      isLastPageInSection: completedPage.isLastPageInSection,
    };

    if (!section) {
      return {
        ...workshop,
        sections: [...workshop.sections, { id: completedPage.section_id, pages: [newPage] }],
      };
    }

    const updatedSection = {
      ...section,
      pages: [...section.pages.filter(page => page.id !== completedPage.page_id), newPage],
      ...(completedPage.isLastPageInSection && { sectionCompleted: true }),
    };

    const updatedSections = workshop.sections.map(sec =>
      sec.id === section.id ? updatedSection : sec,
    );

    return {
      ...workshop,
      sections: updatedSections,
      ...(completedPage.isLastSectionInWorkshop &&
        completedPage.isLastPageInSection && { workshopCompleted: true }),
      ...(completedPage.isBadgePage && { badgeCompleted: true }),
    };
  });

  const newWorkshop = {
    id: completedPage.workshop_id,
    sections: [
      {
        id: completedPage.section_id,
        pages: [
          { id: completedPage.page_id, isLastPageInSection: completedPage.isLastPageInSection },
        ],
      },
    ],
  };

  if (!completed?.some(workshop => workshop.id === completedPage.workshop_id)) {
    update.push(newWorkshop);
  }

  if (userID) {
    const minifyPageContext = {
      ...currentPageContext,
      workshops: [],
      workshopContext: {},
      sectionQuizzes: [],
    };

    const docRef = database ? doc(database, "users", userID) : undefined;

    if (docRef) {
      updateDoc(docRef, {
        lastActive: new Date(),
        pageContext: minifyPageContext ?? {},
        engagement: engagement ?? [],
        completed: update,
      });
    }
  }

  return update;
};

export const useReturnCompleted = () => {
  const [allSanityWorkshops, setAllSanityWorkshops] = useState<SanityWorkshop[] | null>(null);
  const [sortedCompletedWorkshops, setSortedCompletedWorkshops] = useState<any>();
  const [workshopCompletedIndex, setWorkshopCompletedIndex] = useState<number | null>(null);

  const { completed } = useStore();

  const sortWorkshops = () => {
    if (!allSanityWorkshops) {
      return;
    }

    if (completed == null) {
      setSortedCompletedWorkshops(allSanityWorkshops);
      return;
    }

    let workshopIndex = 0;

    const sortedWorkshops = allSanityWorkshops.map((workshop, index) => {
      const workshopInCompleted = completed.find(
        completedWorkshop => completedWorkshop.id === workshop._id,
      );

      if (workshopInCompleted) {
        if (workshopInCompleted.workshopCompleted) {
          workshopIndex = index + 1;
        }
        let sectionIndex = 0;

        // console.log({ workshopInCompleted });

        const sortedSections = workshop?.sections?.map((section, index) => {
          // console.log({ section });

          const sectionInCompleted = workshopInCompleted.sections.find(
            completedSection => completedSection?.id === section?._id,
          );

          if (sectionInCompleted) {
            // console.log(sectionInCompleted);

            if (sectionInCompleted.sectionCompleted) {
              sectionIndex = index + 1;
            }

            let pageIndex = 0;
            const sortedPages = section?.pages?.map((page, index) => {
              const pageInCompleted = sectionInCompleted.pages.find(
                completedPage => completedPage?.id === page?._id,
              );

              const completed = Boolean(pageInCompleted);
              if (completed) {
                pageIndex = index;
              }

              return {
                ...page,
                completed,
              };
            });

            return {
              ...section,
              pages: sortedPages,
              completed: sectionInCompleted.sectionCompleted ? true : false,
              pagesCompletedIndex: pageIndex,
            };
          }

          return {
            ...section,
            completed: false,
          };
        });

        // console.log(sectionIndex);

        return {
          ...workshop,
          sections: sortedSections,
          completed: Boolean(workshopInCompleted.workshopCompleted),
          completedSectionIndex: sectionIndex,
          badgeCompleted: workshopInCompleted.badgeCompleted,
        };
      }

      return {
        ...workshop,
        completed: false,
      };
    });

    setSortedCompletedWorkshops(sortedWorkshops);
    setWorkshopCompletedIndex(workshopIndex);
  };

  useEffect(() => {
    if (allSanityWorkshops) {
      sortWorkshops();
    }
  }, [allSanityWorkshops]);

  useEffect(() => {
    // console.log({ sortedCompletedWorkshops });
  }, [sortedCompletedWorkshops]);

  return { setAllSanityWorkshops, sortedCompletedWorkshops, workshopCompletedIndex };
};

function getLastPathSegment(url) {
  const match = url.match(/([^\/]+)\/?$/);
  return match ? match[1] : null;
}

export const useSaveEvaluation = async (
  workshop?: any,
  evaluation?: any,
  userID?: string,
  title?: string,
) => {
  //Save the evalutaion
  if (!isBrowser()) return null;
  const evalPath = `${workshop?.id}-${getLastPathSegment(window.location.pathname)}`;
  console.log({ evalPath });

  if (workshop && evaluation) {
    const docRef = database ? doc(database, "users", userID, "evaluations", evalPath) : undefined;

    if (docRef) {
      return setDoc(docRef, { answers: evaluation, title: title }, { merge: true });
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const useSaveEngagement = (currentEngagement: any, engagement: EngagementProps) => {
  const updatedEngagement = Array.isArray(currentEngagement) ? [...currentEngagement] : [];

  const existingPage = updatedEngagement.find(item => item.pageID === engagement.pageID);

  if (existingPage) {
    const filteredActivities = existingPage.activities.filter(
      item =>
        !(
          item.uniqueKey === engagement.uniqueKey &&
          item.engagementType === engagement.engagementType
        ),
    );

    // Add the new activity to the filtered array
    const updated = [...filteredActivities, engagement];

    existingPage.activities = updated;
  } else {
    updatedEngagement.push({
      pageID: engagement.pageID,
      pageTitle: engagement.pageTitle,
      workshopID: engagement.workshopID,
      activities: [engagement],
    });
  }

  return updatedEngagement;
};

export const useCalculateEngagement = (data: any) => {
  if (data == null)
    return {
      interactionCount: 0,
      initialCount: 0,
      engagementScore: 0,
    };
  let initialCount = 0;
  let interactionCount = 0;

  data.forEach(page => {
    if (page?.workshopID == null) return;
    page.activities.forEach(activity => {
      if (activity.engagementType === "initial") {
        initialCount++;
      } else if (activity.engagementType === "interaction") {
        interactionCount++;
      }
    });
  });

  if (initialCount === 0) {
    return {
      interactionCount: 0,
      initialCount: 0,
      engagementScore: 0,
    };
  }

  let engagementScore = (interactionCount / initialCount) * 100;
  if (engagementScore > 100) {
    engagementScore = 100;
  }
  return {
    interactionCount,
    initialCount,
    engagementScore,
  };
};

export const useSetUserValue = (value: any) => {
  const userID = useStore.getState().user?.uid;
  if (userID == null) return;
  const docRef = database ? doc(database, "users", userID) : undefined;
  if (docRef) {
    updateDoc(docRef, {
      lastActive: new Date(),
      ...value,
    });
  }
};
